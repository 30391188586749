import { zodResolver } from '@hookform/resolvers/zod'
import { TextInput } from '@lemonenergy/lemonpie'
import { Button } from '@lemonenergy/lemonpie-components'
import { Form, useLoaderData, useNavigation } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { analyticsEvents } from '~/res/constants/events'
import type { LoginFormSchema } from '~/routes/_root.login.metodo-de-contato/schemas'
import { loginFormSchema } from '~/routes/_root.login.metodo-de-contato/schemas'
import { trackEvent } from '~/utils/analytics/events'
import { cpfPattern } from '~/utils/masks'

interface LoginProps {
  openHelpDialog: () => void
}

const LoginForm = ({ openHelpDialog }: LoginProps) => {
  const loaderData = useLoaderData()
  const navigation = useNavigation()
  const { formState, register, setError, clearErrors } =
    useForm<LoginFormSchema>({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      resolver: zodResolver(loginFormSchema),
    })

  useEffect(() => {
    setError('document', { message: loaderData?.error })
  }, [loaderData, setError])

  useEffect(() => {
    if (formState.errors.document) {
      trackEvent({
        name: 'warning',
        label: analyticsEvents.login.invalid_cpf,
        interaction_result: formState.errors.document.message,
      })
    }
  }, [formState.errors.document])

  const isLoading =
    navigation.state !== 'idle' &&
    navigation.location.pathname === '/login/metodo-de-contato'

  return (
    <Form
      className="flex flex-col justify-between px-large py-main"
      method="post"
      action="/login/metodo-de-contato"
      replace
    >
      <TextInput
        autoFocus
        label="CPF"
        mask={cpfPattern}
        error={formState.errors.document?.message}
        onFocus={(e: BaseSyntheticEvent) => {
          clearErrors('document')
          trackEvent({
            event: e,
            name: 'input_start',
            label: analyticsEvents.login.cpf_input_start,
          })
        }}
        {...register('document', {
          onBlur: (e: BaseSyntheticEvent) => {
            trackEvent({
              event: e,
              name: 'input_submit',
              label: analyticsEvents.login.cpf_input_submit,
              interaction_result: e.target.value,
            })
          },
        })}
        inputMode="numeric"
      />
      <Button
        variant="primary"
        className="my-small"
        loading={isLoading}
        onClick={(e: BaseSyntheticEvent) => {
          trackEvent({
            event: e,
            name: 'click_interaction',
            label: analyticsEvents.login.cpf_next,
            interaction_type: 'button',
          })
        }}
      >
        Continuar
      </Button>
      {loaderData?.error && (
        <Button variant="tertiary" onClick={openHelpDialog}>
          Preciso de ajuda
        </Button>
      )}
    </Form>
  )
}

export default LoginForm
