import { isValidCPF } from '@brazilian-utils/brazilian-utils'
import { z } from 'zod'
import { zfd } from 'zod-form-data'

import { AUTH_ERRORS } from '~/res/constants/errors'

export const loginFormSchema = zfd.formData({
  document: zfd.text(
    z
      .string({
        required_error: AUTH_ERRORS.REQUIRED,
      })
      .refine(isValidCPF, AUTH_ERRORS.INVALID_PERSONAL_DOCUMENT)
      .transform((value) => value.replace(/\D/g, '')),
  ),
})

export type LoginFormSchema = z.infer<typeof loginFormSchema>

export type LoginFormSchemaErrors = z.inferFlattenedErrors<
  typeof loginFormSchema
>
