import { Browser } from '@capacitor/browser'
import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogBody,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  IconButton,
} from '@lemonenergy/lemonpie-components'
import { Help } from '@lemonenergy/lemonpie-icons'
import type { LoaderFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useNavigation } from '@remix-run/react'
import posthog from 'posthog-js'
import { useEffect } from 'react'

import LoginForm from './components/LoginForm'

import NonUserYetButton from '~/components/buttons/NonUserYetButton'
import useDialog, { DialogIdentifier } from '~/hooks/useDialog'
import { externalHelpLinks, support } from '~/res/constants'
import { analyticsEvents } from '~/res/constants/events'
import { AppHelp } from '~/ui/Icons'
import { PageHeader, PageHeaderBackLink, PageHeaderNav } from '~/ui/PageHeader'
import TextButton from '~/ui/TextButton'
import WhatsappButton from '~/ui/WhatsappButton'
import { trackEvent } from '~/utils/analytics/events'
import { sessionStorage } from '~/utils/flash.errors.utils'

export { ignoreDialogRevalidation as shouldRevalidate } from '~/revalidationRules'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { getSession, commitSession } = sessionStorage

  const session = await getSession(request.headers.get('Cookie'))

  return json(
    {
      error: session.get('error'),
    },
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    },
  )
}

export default () => {
  const [helpDialogOpen, openHelpDialog, closeHelpDialog] = useDialog(
    DialogIdentifier.HELP_LOGIN,
  )
  const navigation = useNavigation()

  useEffect(() => {
    if (
      !navigation.location?.state?._isRedirect &&
      navigation.location?.pathname === '/login/metodo-de-contato' &&
      navigation.state === 'loading'
    ) {
      trackEvent({
        name: 'success',
        label: analyticsEvents.login.valid_cpf,
      })
    }
  }, [navigation])

  const onCloseHelpDialog = (e: React.MouseEvent) => {
    trackEvent({
      event: e,
      name: 'click_interaction',
      label: analyticsEvents.help.talk_to_us_no_document_help,
      interaction_type: 'button',
    })

    closeHelpDialog()
  }

  useEffect(() => {
    if (posthog._isIdentified()) posthog.reset()
  }, [])

  return (
    <div className="flex flex-1 flex-col items-center justify-between pb-larger">
      <div className="w-full">
        <PageHeader>
          <PageHeaderNav>
            <PageHeaderBackLink to="/welcome" replace />
            <IconButton
              onClick={() => {
                Browser.open({
                  url: externalHelpLinks['cpf-screen'],
                })
              }}
            >
              <AppHelp />
            </IconButton>
          </PageHeaderNav>
          <h1 className="typography-heading">Entrar</h1>
          <h2 className="typography-subheading">
            Insira seu CPF para entrar e ver as suas contas{' '}
            <TextButton
              onClick={(e) => {
                openHelpDialog()
                trackEvent({
                  event: e,
                  name: 'click_interaction',
                  label: analyticsEvents.help.open('Entrando com CPF'),
                  interaction_type: 'button',
                })
              }}
            >
              <Help size="small" />
            </TextButton>
          </h2>
        </PageHeader>
        <LoginForm openHelpDialog={openHelpDialog} />
      </div>

      <NonUserYetButton />

      <Dialog open={helpDialogOpen} onClose={closeHelpDialog}>
        <DialogBackdrop>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Entrando com CPF</DialogTitle>
              <DialogCloseButton onClick={closeHelpDialog} />
            </DialogHeader>
            <DialogBody>
              <p className="typography-body">
                Na Lemon você entra usando seu CPF pessoal, mesmo se a conta de
                luz estiver em um CNPJ ou no CPF de outra pessoa.
                <br />
                <br />
                Se você precisa acessar um lugar mas não tem seu CPF cadastrado
                fale com a gente que iremos te ajudar.
              </p>
            </DialogBody>
            <DialogFooter>
              <Button variant="primary" onClick={closeHelpDialog}>
                Entendi
              </Button>

              <WhatsappButton
                variant="tertiary"
                message={support.noDocumentMessage}
                onClick={onCloseHelpDialog}
              />
            </DialogFooter>
          </DialogContent>
        </DialogBackdrop>
      </Dialog>
    </div>
  )
}
